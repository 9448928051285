import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import CashierIcon from "../assets/svg/CashierIcon";
import logo from "../assets/svg/logo_new.png";
import BotHeadIcon1 from "../assets/images/icons/BotHeadIcon1.png";
import BotHeadIcon2 from "../assets/images/icons/BotHeadIcon2.png";
import BotHeadIcon3 from "../assets/images/icons/BotHeadIcon3.png";
import BotHeadIcon4 from "../assets/images/icons/BotHeadIcon4.png";
import BotHeadIcon123 from "../assets/images/icons/casino123.png";
import BotHeadIcon5 from "../assets/images/icons/BotHeadIcon5.png";
import BotHeadIcon6 from "../assets/images/icons/BotHeadIcon6.png";
import BotHeadIcon7 from "../assets/images/icons/BotHeadIcon8.png";
import { useSelector } from "react-redux";
import TextMover from "../views/components/TextMover";
import { AiOutlineClose } from "react-icons/ai";
import { getNotifications } from "../redux/app/actions";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [visible, setVisible] = useState(false);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };
  useEffect(() => {
    loadNotifications();
    return () => {};
  }, []);
  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="Announcement">
          <div className="latest"></div>
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }}>{notf?.content}</label>
            ))}
          </marquee>
        </div>
      )) ||
        null}
      <header className="header beforeheader">
        {visible ? (
          <div className="marqu">
            <marquee>
              GET 7% BONUS ON EVERY DEPOSIT | Get ID Now +91 8888880808
            </marquee>
            <span onClick={() => setVisible(!visible)}>
              <AiOutlineClose />
            </span>
          </div>
        ) : (
          ""
        )}
        <div className="top_head">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center header-section">
                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>
                  <div className="middle-part">
                    <Navbar expand="lg">
                      <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="me-auto">
                            <Nav.Link href="/">
                              <HomeIcon /> Home
                            </Nav.Link>
                            <Nav.Link href="/sign-in" className="blink">
                              <SportsIcon className="" /> Exchange
                            </Nav.Link>

                            <Nav.Link href="/sign-in">
                              <GamesIcon /> Ezugi
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              <CashierIcon /> Cashier
                            </Nav.Link>
                          </Nav>
                        </Navbar.Collapse>
                      </Container>
                    </Navbar>
                  </div>
                  <div className="headerRight ">
                    {/* <ul className="social">
                    <li>
                      <a
                        className="panel"
                        href={
                          appDetails?.TELEGRAM != ""
                            ? appDetails?.TELEGRAM
                            : "#"
                        }
                        target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                      >
                        <TelegramIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        className="panel"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? appDetails?.WHATS_APP_URL
                            : "#"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <WhatsappIcon />
                      </a>
                    </li>
                  </ul> */}
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/sign-in");
                      }}
                      className="button-primary btn_signin"
                    >
                      SIGN IN
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/mobile-number");
                      }}
                      className="button-primary btn_signup ms-2"
                    >
                      REGISTER
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="bottom_head">
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <ul>
                  <li>
                    <a href="/sign-in">
                      <img src={BotHeadIcon1} alt="bottom head link icon" />
                      <span>Inplay</span>
                    </a>
                  </li>
                  <li>
                    <a href="/sign-in">
                      <img src={BotHeadIcon6} alt="bottom head link icon" />
                      <span>CASINO</span>
                    </a>
                  </li>
                  {/* <li>
                  <a href="/sign-in">
                    <img src={BotHeadIcon3} alt="bottom head link icon" />
                    <span>Soccer</span>
                  </a>
                </li> */}
                  <li>
                    <a href="/sign-in">
                      <img src={BotHeadIcon4} alt="bottom head link icon" />
                      <span>Aviator</span>
                    </a>
                  </li>
                  {/* <li>
                  <a href="/sign-in">
                    <img src={BotHeadIcon123} alt="bottom head link icon" />
                    <span>Casino</span>
                  </a>
                </li> */}
                  <li>
                    <a href="/sign-in">
                      <img src={BotHeadIcon5} alt="bottom head link icon" />
                      <span>DragonTiger</span>
                    </a>
                  </li>

                  <li>
                    <a href="/sign-in">
                      <img src={BotHeadIcon7} alt="bottom head link icon" />
                      <span>AndarBahar</span>
                    </a>
                  </li>
                  <li>
                    <a href="/sign-in">
                      <img src={BotHeadIcon2} alt="bottom head link icon" />
                      <span>Cricket</span>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Login now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Login
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Register Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
      </header>
    </>
  );
};

export default Header;
